<template>
  <perseu-card :title="title">
    <template slot="content">
      <v-container grid-list-md v-if="loadedData">
        <v-form ref="form">
          <v-text-field v-model.trim="par.name" label="Nome" />
          <v-switch v-model="par.isFirstLevel" label="Par Nível 1?" />
          <types-select
            label="Tipo"
            v-model="par.type"
            :rules="[$rules.required]"
          />
          <category-select label="Categorias" v-model="par.categories" />
          <pathogen-select label="Patógeno" v-model="par.pathogens" />
          <symptoms-select label="Sintomas" v-model="par.symptoms" />
          <point-select
            label="Ponto de Rastreio"
            v-model="par.points.trackingPoint"
            type="0"
            :rules="[$rules.required]"
          />
          <point-select
            label="Ponto de Impactação"
            v-model="par.points.impactionPoint"
            type="1"
            :rules="[$rules.required]"
          />
          <v-textarea
            v-model="par.recommendations"
            label="Recomendações"
            auto-grow
            outlined
            counter
            maxlength="2000"
          />
          <v-textarea
            v-model="par.description"
            label="Descrição"
            auto-grow
            outlined
            counter
            maxlength="2000"
          />
          <points-order-select
            label="Pares para checar"
            v-model="par.checkPointsOrder"
            multiple
          />
        </v-form>
      </v-container>
    </template>
    <template slot="actions">
      <v-btn @click="back">Voltar</v-btn>
      <v-btn color="secondary" @click="save">Salvar</v-btn>
    </template>
  </perseu-card>
</template>

<script>
import { save, findOne } from "@/services/pares-biomagneticos-service";
import { pagesNames } from "@/router/pages-names";

export default {
  components: {
    "point-select": () => import("@/components/Points/Select"),
    "pathogen-select": () => import("@/components/Pathogens/Select"),
    "symptoms-select": () => import("@/components/Symptoms/Select"),
    "types-select": () => import("@/components/Types/Select"),
    "category-select": () => import("@/components/Categories/Select"),
    "points-order-select": () => import("@/components/PointsOrder/Select"),
  },
  data: () => ({
    headersLinhas: [
      { text: "Nome", value: "name" },
      { text: "Ações", value: "actions" },
    ],
    par: {
      points: {},
      isFirstLevel: false,
      checkPares: [],
      pathogens: [],
      symptoms: [],
      categories: [],
    },
    loadedData: true,
  }),
  computed: {
    title() {
      return (this.id ? "Edição de" : "Novo") + " Par Biomagnético";
    },
    loading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
  /* watch: {
    obj: {
      handler(value) {
        console.log(value);
      },
      deep: true
    }
  }, */
  created() {
    if (this.$route.query.id) {
      this.find(this.$route.query.id);
      if (this.$route.query.duplicate) {
        this.duplicate();
      }
    }
  },
  methods: {
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        let response = await save(this.par);
        if (this.par.id) {
          this.$emit("updated", response.data);
        } else {
          this.$emit("created", response.data);
        }
        this.$toasted.global.defaultSuccess();
        this.$router.push({ name: pagesNames.PARES_BIOMAGNETICOS_LIST });
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        this.loadedData = false;
        let response = await findOne(this.$route.query.id);
        this.par = response.data;
        console.log(this.par);
      } catch (error) {
        this.$toasted.global.defaultError();
      } finally {
        this.loadedData = true;
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async duplicate() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await findOne(this.$route.query.id);
        this.par = response.data;
        this.par.id = null;
        console.log(this.par);
        this.par.points.id = null;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
